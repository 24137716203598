
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class BookWidgetView extends Vue {
  private channelUserId: string | null = null

  mounted() {
    const { channelUserId } = this.$route.params;
    console.log(this.$route.params)
    this.channelUserId = channelUserId;
    //this.redirect()
  }

  redirect() {
    console.log("{ redirect } start timer...")
    setTimeout(() => {
      console.log("{ redirect } do location replace...")
     // window.location.replace("https://booking.medflex.ru/?user=8d3ec98db85b9c11981b1d1435c4bbc9&source=3")
      window.location.href = "https://booking.medflex.ru/?user=8d3ec98db85b9c11981b1d1435c4bbc9&source=3"
    }, 3000)
  }
}
