export class Bitrix24Connection {
    host?: string;
    categoryId?: number;
    stageId?: string;
    key?: string;
    contactTypeId?: string;
    dealTypeId?: string;
    sourceId?: number;
    originName?: string;
    originHost?: string;


    constructor(from: Bitrix24Connection | undefined | null = null) {
        if (from?.host) this.host = from.host
        if (from?.categoryId) this.categoryId = from.categoryId
        if (from?.stageId) this.stageId = from.stageId
        if (from?.key) this.key = from.key
        if (from?.contactTypeId) this.contactTypeId = from.contactTypeId
        if (from?.dealTypeId) this.dealTypeId = from.dealTypeId
        if (from?.sourceId) this.sourceId = from.sourceId
        if (from?.originName) this.originName = from.originName
        if (from?.originHost) this.originHost = from.originHost
    }



    buildHref(entityType: string, entityId: number): string {
        if (!this.host || !this.host.startsWith('https://')) {
            return ''
        }
        let host = this.host
        if (!host.endsWith('/')) {
            host += '/'
        }
        return `${host}crm/${entityType}/details/${entityId}/`
    }
}