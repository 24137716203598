
import {Vue, Component, Prop} from 'vue-property-decorator';
import {Chat, ChatPlan} from "@/models";
import TParamFlexDisplay from "@/components/lib/TParamFlexDisplay.vue";



@Component({
  components: {TParamFlexDisplay}
})
export default class ChatBitrix24Panel extends Vue {
  @Prop() chat?: Chat

  private inAction = false
  mounted() {
    console.log("{ ChatBitrix24Panel } nas mounted")
  }

  buildHref(entityType: string, entityId: number): string {
    let host = this.chat?.channel?.bitrix24?.host || ''
    if (!host.endsWith('/')) {
      host += '/'
    }
    return `${host}crm/${entityType}/details/${entityId}/`
  }

  exportToBitrix24() {
    if (this.chat) {
      this.inAction = true
      Chat.exportToBitrix24(this.chat).then(result => {
        console.log("{ removeFromBitrix24 } result", result)
        setTimeout(() => {
          console.log("{ removeFromBitrix24 } load channelUser after time...")
          this.chat?.load('channelUser').then(() => this.inAction = false)
        }, 5000);
      })
    }
  }
  removeFromBitrix24() {
    if (this.chat) {
      this.inAction = true
      Chat.removeFromBitrix24(this.chat).then(result => {
        console.log("{ removeFromBitrix24 } result", result)
        setTimeout(() => {
          console.log("{ removeFromBitrix24 } load channelUser after time...")
          this.chat?.load('channelUser').then(() => this.inAction = false)
        }, 5000);
      })
    }
  }

}
