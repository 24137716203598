import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/deprecated/HomeView.vue'
import ReportView from '../views/report/ReportView.vue'
import AnswerReportView from "../views/report/AnswerReportView.vue";
import ChatsView from "@/views/ChatsView.vue";
import RootChatsView from "@/views/RootChatsView.vue";
import ChannelIdTest from "@/components/chat/ChannelIdTest.vue";
import ChannelNameTest from "@/components/chat/ChannelNameTest.vue";
import ChatPanel from "@/components/chat/ChatPanel.vue";
import BookWidgetView from "@/views/BookWidgetView.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: false,
      title: 'ChatPlant'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/deprecated/AboutView.vue'),
    meta: {
      requiresAuth: true,
      title: 'ChatPlant'
    }
  },
  {
    path: '/assistants',
    name: 'assistants',
    component: () => import('../views/AssistantView.vue'),
    meta: {
      requiresAuth: true,
      title: 'ChatPlant'
    },
    children: [
      {
        path: ':assistantId',
        name: 'assistantCard',
        component: () => import('../components/assistant/AssistantCard.vue'),
        props: true
      },
      // {
      //   path: 'create',
      //   name: 'createAssistant',
      //   component: () => import('../components/assistant/AssistantForm.vue')
      // }
    ]
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../views/ProjectsView.vue'),
    meta: {
      requiresAuth: true,
      title: 'ChatPlant'
    }
  },
  {
    path: '/projects/:projectId',
    name: 'project',
    component: () => import('../views/ProjectCard.vue'),
    meta: {
      requiresAuth: true,
      title: 'ChatPlant'
    }
  },
  {
    path: '/channelUsers',
    name: 'channelUsers',
    component: () => import('../views/ChannelUsersView.vue'),
    meta: {
      requiresAuth: true,
      title: 'ChatPlant'
    }
  },
  // {
  //   path: '/report.html/external/:key::channelUserId/:valueName',
  //   name: 'reportByAnswers',
  //   component: AnswerReportView
  // },
  {
    path: '/report.html/external/:key::code::id/vas',
    name: 'reportWithParams',
    component: ReportView,
    // meta: {
    //   title: 'ВАШ.TEMED: Мониторинг уровня боли',
    //   metaTags: [
    //     {
    //       name: 'description',
    //       content: 'Отслеживайте историю мониторинга уровня боли по визуально-аналоговой шкале (ВАШ)'
    //     },
    //     {
    //       property: 'og:description',
    //       content: 'Отслеживайте историю мониторинга уровня боли по визуально-аналоговой шкале (ВАШ)'
    //     },
    //     {
    //       property: 'og:image',
    //       content: '/img/temed-vas-512.png'
    //     }
    //   ]
    // },
  },
  {
    path: '/report.html/external/:key::channelUserId/:valueName',
    name: 'reportByAnswers',
    component: AnswerReportView
  },
  {
    path: '/report.html/applicable/:key::channelUserId/:valueName',
    name: 'internalReportByAnswers',
    component: AnswerReportView
  },
  {
    path: '/chats',
    name: 'allChats',
    component: ChatsView,
    children: [
      {
        path: ':chatId([0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12})',
        name: 'allChatsChat',
        component: ChatPanel,
        props: true
      }
    ],
    meta: {
      requiresAuth: true,
      title: 'ChatPlant'
    }
  },
  {
    path: '/chats/:channelName',
    name: 'chatsByChannel',
    component: ChatsView,
    props: true,
    children: [
      {
        path: ':chatId([0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12})',
        name: 'chatsByChannelChat',
        component: ChatPanel,
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ],
    meta: {
      requiresAuth: true,
      title: 'ChatPlant'
    }
  },
  {
    path: '/book/widget/:channelUserId',
    name: 'bookWidgetView',
    component: BookWidgetView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

router.beforeEach(async (to, from, next) => {
  //console.log("[router.beforeEach] to: ", to.path, {to, from, next})

  if (to.path === "/silent-check-sso.html") {
    //console.log("[router.beforeEach] immediately do next()")
    return next();
  }

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  /* auth route guard */
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //console.log("[router.beforeEach] route with auth guard")
    // We wait for Keycloak init, then we can call all methods safely
    while (router.app.$keycloak.createLoginUrl === null) {
      await sleep(100)
      //console.log("[router.beforeEach] sleep 100ms")
    }
    //console.log("[router.beforeEach] Keycloak init has init. Authenticated: ", router.app.$keycloak.authenticated)
    if (router.app.$keycloak.authenticated) {
      if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
      }
      next()
    }
    else {
      const loginUrl = router.app.$keycloak.createLoginUrl?.()
      //console.log("[router.beforeEach] loginUrl: ", loginUrl)
      if (loginUrl) {
        window.location.replace(loginUrl)
      }
    }
  }
  else {
    //console.log("[router.beforeEach] route without auth")
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // // `/nested`'s will be chosen.
    // const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    //
    // // Find the nearest route element with meta tags.
    // const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    //
    // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode?.removeChild(el));

    // If a route with a title was found, set the document (page) title to that value.
    const tagOgTitle = document.createElement('meta');
    tagOgTitle.setAttribute('property', 'og:title');

    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
      tagOgTitle.setAttribute('content', nearestWithTitle.meta.title)
      tagOgTitle.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(tagOgTitle)
      console.log('nearestWithTitle og:title', tagOgTitle)
    }
    else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
      tagOgTitle.setAttribute('content', previousNearestWithMeta.meta.title)
      tagOgTitle.setAttribute('data-vue-router-controlled', '');
      console.log('previousNearestWithMeta og:title', tagOgTitle)
      document.head.appendChild(tagOgTitle)
    }

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map((tagDef: any) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
        // Add the meta tags to the document head.
        .forEach((tag: any) => document.head.appendChild(tag));

    //console.log("[router.beforeEach] execute next()")
    return next();
  }
})

export default router
