
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {Assistant, Channel, ChatPlan} from "@/models";
import {ChannelType} from "@/models/types/Types";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {Bitrix24Connection} from "@/models/embedded/Bitrix24Connection";

@Component
export default class ChannelEditForm extends Vue {
  @Prop() private channel!: Channel;
  @Prop() private editMode!: boolean

  private selectedPlanId: string | null = null;
  private selectedAssistantId: string | null = null;
  private bitrix24connection: Bitrix24Connection  = new Bitrix24Connection();

  private linkBase64copied = false;

  private chatPlans = new ResourceCollection<ChatPlan>(
      ChatPlan.api().page(1).perPage(100)
  )
  private assistants = new ResourceCollection<Assistant>(
      Assistant.api().page(1).perPage(100)
  )

  private channelTypeOptions = [
    { value: ChannelType.TELEGRAM, text: "Telegram" },
    { value: ChannelType.WHATSAPP, text: "WhatsApp" },
    { value: ChannelType.WECHAT, text: "WeChat" },
  ]

  mounted() {
    //console.log('[ ChannelEditForm#mounted ] channel: ', this.channel)
    this.init()
  }

  @Watch("channel")
  init() {
    if (this.channel) {
      this.selectedPlanId = this.channel.defaultPlan ? this.channel.defaultPlan.id : null
      this.selectedAssistantId = this.channel.defaultAssistant ? this.channel.defaultAssistant.id : null
      this.bitrix24connection = this.channel.bitrix24 ? this.channel.bitrix24 : new Bitrix24Connection()
    }
    this.chatPlans.requestItems();
    this.assistants.requestItems();
  }

  setBitrix24Value(fieldName: string, value: string | number | null) {
    if (this.channel) {
      const bitrix24 = new Bitrix24Connection(this.channel.bitrix24)
      this.$set(bitrix24, fieldName, value === "" ? null : value)
      this.channel.bitrix24 = bitrix24
    }
  }


  @Watch("bitrix24connection")
  onBitrix24connectionChange() {
    console.log("{ onBitrix24connectionChange } ", this.bitrix24connection)
  }
  onPlanSelect(selectedId: string) {
    this.selectedPlanId = selectedId;

    if (selectedId==null) {
      this.channel.defaultPlan = null;
      return
    }

    const selectedPlan = this.chatPlans.items.find(it => it.id === selectedId)
    if (selectedPlan) {
      this.channel.defaultPlan = selectedPlan;
    }
  }

  onAssistantSelect(selectedId: string) {
    this.selectedAssistantId = selectedId;

    if (selectedId==null) {
      this.channel.defaultAssistant = null;
      return
    }

    const selectedAssistant = this.assistants.items.find(it => it.id === selectedId)
    if (selectedAssistant) {
      this.channel.defaultAssistant = selectedAssistant;
    }
  }

  get chatPlanOptions() {
    if (!this.chatPlans?.items) return [];
    return this.chatPlans.items.map(item => {
      return { value: item, text: `${item.code} | ${item.description}` }
    })
  }

  rollbackModel() {
    this.channel.$_rollbackChanges();
    this.selectedPlanId = this.channel.defaultPlan ? this.channel.defaultPlan.id : null
  }

  @Emit()
  close() {
    return false;
  }

  @Emit()
  onCreate() {
    //console.log('[ ChannelEditForm#onCreate ] save and emit onCreate channel: ', this.channel)
    return this.channel.save().then(savedChannel => {
      return savedChannel
    })
  }

  @Emit()
  onCreateCancel() {
    //console.log("[ ChannelEditForm::onCreateCancel ] emit [this] channel: ", this.channel)
    return this.channel;
  }
  closeWithRollback() {
    this.rollbackModel();
    this.close();
  }

  save() {
    //console.log('[ ChannelEditForm#save ] channel: ', this.channel)
    return this.channel.save()
  }

  saveAndClose() {
    this.save().then(() => {
      this.close();
    })
  }

  async copyToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      this.linkBase64copied = true;
      //console.log('copy: ', text)
      setTimeout(() => {
        this.linkBase64copied = false;
      }, 3000);
    } catch($e) {
      //console.warn('Cannot copy to clipboard...');
    }
  }
}
