
import {Vue, Component, Prop} from 'vue-property-decorator';
import {Chat, ChatPlan} from "@/models";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";

// import debounce from 'lodash/debounce'
import TAheadSelector from "@/components/lib/selector/TAheadSelector.vue";
import {Debounce} from "vue-debounce-decorator";


@Component({
  components: {TAheadSelector},
  computed: {
    ChatPlan() {
      return ChatPlan
    }
  }
})
export default class ChatMaintenancePanel extends Vue {
  @Prop() chat?: Chat

  private selectedPlan: ChatPlan | null = null

  private chatPlanQuery: string | null = null
  private chatPlans = new ResourceCollection<ChatPlan>(
      ChatPlan.api()
          .page(1)
          .perPage(25)
          .orderBy('name'),
      'chatPlans_autocomplete'
  );

  private query = "";


  mounted() {
    console.log("{ ChatMaintenancePanel } nas mounted")
  }

  onSelect(item: ChatPlan | null) {
    console.log("{ ChatMaintenancePanel:onSelect } receive: ", item)
    this.selectedPlan = item
  }

  @Debounce(300)
  lookupChatPlans(value: string) {
    console.log("{ ChatMaintenancePanel#search } value=" + value)
    this.chatPlans.setFilter({ "code": value })
  }

  onSelectChatPlan(selectedPlan: ChatPlan) {
    console.log("{ ChatMaintenancePanel#onSelectChatPlan } value=", selectedPlan)
  }
}
