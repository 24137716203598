
import {Vue, Component, Prop, Emit, Watch} from 'vue-property-decorator';
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {Debounce} from "vue-debounce-decorator";
import {ApiResource} from "@/models/base/ApiResource";

@Component({})
export default class TAheadSelector<T extends ApiResource> extends Vue {
  @Prop() collection!: ResourceCollection<T>
  @Prop() value!: T | null
  @Prop() selectedId!: string
  @Prop() selectedValue!: string
  @Prop() label!: string
  @Prop() searchField!: string
  @Prop() placeholder!: string

  private isFocused = false
  private queryString = '';
  get id() {
    return Math.floor(Math.random() * 100000)
  }

  // eslint-disable-next-line no-undef
  @Emit("on-select")
  onSelect(item: T) {
    console.log("{ TAheadSelector:onSelect } item: ", item)
    return item;
  }

  // @Watch('value')
  // onValueChange() {
  //   this.queryString = this?.value['name']
  // }

  @Debounce(300)
  onQueryStringChange() {
    console.log("{ TAheadSelector:onQueryStringChange } query: " + this.queryString)
    if (this.collection && this.queryString.trim().length > 2 ) {
      const filter: { [key: string]: string; }  = {}
      filter[this.searchField] = this.queryString
      console.log("{ TAheadSelector:onQueryStringChange } filter: ", filter)
      this.collection.setFilter(filter)
    }
    else {
      this.collection.clearItems()
    }
  }
}
