import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import { DayjsMeta } from "@/decorators/decorators";
import {Attr, BelongsTo, HasMany, Meta} from "@temed/vue-jsonapi-orm";
import {User} from "@/models/User";
import {Employee} from "@/models/Employee";
import {ChannelUserStateHistory} from "@/models/ChannelUserStateHistory";
import {Dayjs} from "dayjs";
import {Profile} from "@/models/Profile";
import {Channel} from "@/models/Channel";
import {ChannelUserPlan} from "@/models/ChannelUserPlan";
import {Bitrix24RelatedData} from "@/models/embedded/Bitrix24RelatedData";
import {UtmData} from "@/models/embedded/UtmData";

export class ChannelUser extends ApiResourceWithDates {
    static jsonApiType = "channelUsers";

    @DayjsMeta() lastAction?: string;
    @DayjsMeta() registerDate?: Dayjs;
    @Meta() state?: string;
    @Meta() prevState?: string;
    @Attr() name?: string;
    @Attr() phone?: string;
    @Attr() email?: string;
    @Attr() mskTimeShift?: number;
    @Attr() payState?: string;
    @Meta() prevPayState?: string;
    @Attr() telegramId?: number;
    @Attr() sourceTag?: string;
    @Attr() tariff?: string;
    @Attr() scenarioState?: string;

    @BelongsTo() channel?: Channel;
    @BelongsTo() user?: User;
    @BelongsTo() employee?: Employee;
    @BelongsTo() profile?: Profile;

    @HasMany() stateHistory?: ChannelUserStateHistory[]
    @HasMany() channelUserPlans?: ChannelUserPlan[]

    @Meta() bitrix24RelatedData?: Bitrix24RelatedData
    @Meta() utm?: UtmData

    get preferName() {
        if (this.profile) {
            //console.info('channelUser.preferName [from profile]: ', this.profile.fio)
            return this.profile.fio
        }
        if (this.user) {
            //console.info('channelUser.preferName [from user]: ', this.user.preferName)
            return this.user.preferName
        }
    }
}