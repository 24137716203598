import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import {StompService} from "@/services/StompService";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

/* import specific icons from FontAwesome (free) packages */
/* import solid icons */
import {
  faUserSecret,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faArrowUpRightFromSquare,
  faAddressCard as fasAddressCard,
  faXmark,
  faGear,
  faToggleOn,
  faToggleOff,
  faSpinner,
  faCircleNotch,
  faTerminal,
  faChevronRight,
  faStar as fasStar,
  faRobot,
  faPlus,
  faRotate,
  faRepeat,
  faPlay,
  faPause,
  faStop,
  faPen,
  faReply,
  faFilter,
  faPaperclip,
  faArrowTurnUp,
  faArrowUp,
  faInfo,
  faCodeMerge,
  faMagnifyingGlass,
  faTurnUp,
  faCircleDown as fasCircleDown,
  faChevronDown, faChevronUp,
  faQuoteLeft, faQuoteRight,
  faMessage as fasMessage,
  faComment as fasComment,
  faAlignLeft,
  faBookmark as fasBookmark,
  faUsersViewfinder,
  faHouse,
  faCommentSlash,
  faSlash,
  faDiagramProject,
  faWandMagicSparkles
} from '@fortawesome/free-solid-svg-icons'

/* import regular icons */
import {
  faAddressCard as farAddressCard,
  faPenToSquare,
  faTrashCan,
  faCopy,
  faCircleStop,
  faCirclePlay,
  faCirclePause,
  faMessage,
  faComments, faComment, faCommentDots,
  faHeart, faEye, faFaceSmile,
  faHand, faHandBackFist,
  faCircleDown as farCircleDown,
  faBell, faBellSlash,
  faBookmark as farBookmark,
  faPlusSquare, faMinusSquare
} from '@fortawesome/free-regular-svg-icons'

import { faTelegram, faWeixin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import KeycloakPlugin from "@/plugins/keycloak";
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import { VueKeycloakInstance } from "@dsb-norge/vue-keycloak-js/dist/types";

import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";


import './app.scss'
import TMenuSection from "@/components/lib/menu/TMenuSection.vue";

// import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete';
// Vue.component('vue-bootstrap-autocomplete', VueBootstrapAutocomplete)

/* add icons to the library */
library.add(faUserSecret, faArrowRightToBracket, faArrowRightFromBracket, fasAddressCard, farAddressCard, faTelegram, faWeixin, faWhatsapp, faXmark, faGear, faToggleOn, faToggleOff, faPenToSquare, faTrashCan, faSpinner,
    faCircleNotch, faCopy, faTerminal, faChevronRight, fasStar, faRobot, faPlus, faRotate, faRepeat, faCircleStop, faCirclePlay, faCirclePause, faPlay, faPause, faStop, faPen, faMessage, fasMessage)
library.add(faComments, faComment, faCommentDots, fasComment, faCommentSlash, faSlash)
library.add(faReply, faFilter)
library.add(faHeart, faEye)
library.add(faPaperclip, faArrowUp, faArrowTurnUp)
library.add(faFaceSmile, faInfo, faCodeMerge)
library.add(faHand, faHandBackFist)
library.add(faMagnifyingGlass)
library.add(faTurnUp)
library.add(fasCircleDown, farCircleDown, faChevronDown, faChevronUp)
library.add(faQuoteLeft, faQuoteRight)
library.add(faAlignLeft)
library.add(faBell, faBellSlash)
library.add(fasBookmark, farBookmark)
library.add(faPlusSquare, faMinusSquare)
library.add(faUsersViewfinder)
library.add(faHouse)
library.add(faDiagramProject)
library.add(faWandMagicSparkles)
library.add(faArrowUpRightFromSquare)

/* add font awesome icon component */
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)

Vue.config.productionTip = false

dayjs.locale("ru");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

declare module 'vue/types/vue' {
  // 3. Declare augmentation for Vue
  interface Vue {
    $dayjs(date?: dayjs.ConfigType, option?: dayjs.OptionType, locale?: string): dayjs.Dayjs;
    $keycloak: VueKeycloakInstance
    $stompClient: StompService
    $updateInterval?: number
  }
}

declare global {
  interface Window { stompService: StompService; }
}


const keycloakInitOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: 'demo',
  clientId: 'platform-client'
}

Vue.prototype.$dayjs = dayjs
Vue.prototype.$stompClient = new StompService();

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


echarts.use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);


Vue.use(VueKeyCloak, {
  config: keycloakInitOptions,
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
    // flow: 'hybrid'
  },
  logout: {
    redirectUri: window.location.origin
  },
  // onAuthRefreshSuccess: (keycloak) => {
  //   //console.log(`onAuthRefreshSuccess: `, keycloak)
  // },
  onAuthRefreshError: (keycloak) => {
    //console.log(`onAuthRefreshError: `, keycloak)
    keycloak.login()
  },
  onReady: (keycloak) => {
    console.log(`I wonder what Keycloak returns: `, keycloak)

    const vueInstance = new Vue({ router, store, render: h => h(App) })
        .$mount('#app')

    /*
    * Если пользователь аутентифицирован - запускаем таймер контроля токена
    * */
    // if (keycloak.authenticated) {
    //   vueInstance.$updateInterval = setInterval(() => {
    //     if (keycloak.authenticated && keycloak.isTokenExpired(20)) {
    //       console.warn('{ $updateInterval } need to refresh?.. with -1 validity');
    //       keycloak.updateToken(-1)
    //     }
    //   }, 1000)
    // }
  }
})

